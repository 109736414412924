module.exports = [{
      plugin: require('../../../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../plugins/gatsby-plugin-apollo-client/gatsby-browser.js'),
      options: {"plugins":[],"endpointUrl":"https://qa-04.faq.karakuri.ninja/graphql"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
